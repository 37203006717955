<template>
  <div class="search">
    <!-- 头部 -->
    <van-nav-bar
      @click-left="onClickLeft"
      @click-right="onClickRight"
      class="title"
    >
      <template #title>
        <van-search
          ref="searchinput"
          shape="round"
          placeholder="请输入关键词"
          v-model="keywords"
          autofocus
          @search="onSearch"
          @input="inputText"
        />
      </template>
      <template #left>
        <van-icon name="arrow-left" color="#333" size="20" />
      </template>
      <template #right>
        <van-button size="mini" type="danger" @click="doSearch">搜索</van-button>
      </template>
    </van-nav-bar>
    <div class="search_history" v-if="history && history.length > 0">
      <van-cell :border="false" title="最近搜索" :title-style="'max-width:' + (screenWidth - 50) + 'px;'">
        <template #right-icon>
          <van-icon name="delete" @click="removeHistory"></van-icon>
        </template>
        <template #label>
          <van-tag
            class="van-ellipsis"
            v-for="item in history"
            :Key="item"
            @click="onSearch(item)"
          >{{ item }}</van-tag>
        </template>
      </van-cell>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Search',
  data(){
    return {
      screenWidth: document.body.clientWidth, // 屏幕宽
      screeHeight: document.body.clientHeight, // 屏幕高
      keywords: '', // 关键词
      history: [], // 搜索记录
      shareid: 0,
      merchid: 0,
      dudaobrandId: 0
    }
  },
  mounted(){
    
    const text = localStorage.getItem("searchText") ? localStorage.getItem("searchText") : null;
    if (text) {
      if (text.indexOf(",") !== -1) {
        this.history = text.split(',');
      }else{
        this.history.push(text);
      }
    }
    if (this.$route.query.shareid) {
      this.shareid = this.$route.query.shareid
    }else if (localStorage.getItem("DK_MEMBERID")) {
      this.shareid = localStorage.getItem("DK_MEMBERID");
    }
    this.merchid = this.$route.query.merchid ? this.$route.query.merchid : 0;
    this.dudaobrandId = this.$route.query.dudaobrandId ? parseInt(this.$route.query.dudaobrandId) : 0;
    this.$parent.getmerchname(this.merchid, "搜索");
    
    this.init();
  },
  methods:{
    init(){
      let _that = null;
      _that = this;
      setTimeout(() => {
        _that.$refs.searchinput.focus();
      }, 200);
    },
    // 点击左侧按钮
    onClickLeft(){
      this.$router.go(-1);
    },
    // 点击右侧按钮
    onClickRight(){
      console.log('right');
    },
    // 获取输入内容
    inputText(val){
      this.keywords = val;
    },
    // 直接搜索
    onSearch(val){
      if (this.history.indexOf(val) !== -1) {
        this.history.splice(this.history.indexOf(val), 1);
        this.history.unshift(val);
      }else{
        this.history.unshift(val);
      }
      const history = [];
      this.history.forEach((item, index) => {
        if (index < 10) {
          history.push(item);
        }
      })
      this.history = history;
      localStorage.setItem("searchText", history.toString());
      this.$router.replace({ name: "List", query: { keywords: val, shareid: this.shareid, merchid: this.merchid, dudaobrandId: this.dudaobrandId } });
    },
    // 点击搜索按钮
    doSearch(){
      if (this.history.indexOf(this.keywords) !== -1) {
        this.history.splice(this.history.indexOf(this.keywords), 1);
        this.history.unshift(this.keywords);
      }else{
        this.history.unshift(this.keywords);
      }
      const history = [];
      this.history.forEach((item, index) => {
        if (index < 10) {
          history.push(item);
        }
      })
      this.history = history;
      localStorage.setItem("searchText", history.toString());
      this.$router.replace({ name: "List", query: { keywords: this.keywords, shareid: this.shareid, merchid: this.merchid, dudaobrandId: this.dudaobrandId } })
    },
    // 删除搜索记录
    removeHistory(){
      this.history = [];
      localStorage.removeItem("searchText");
    }
  }
}
</script>
<style lang="less">
  .search{
    min-height: 100%;
    width: 100%;
    overflow: hidden;
    position: relative;
    z-index: 2;
    background-color: #ededed;
    .title{
      display: flex;
      .van-nav-bar__title{
        flex: 1;
        max-width: 100%;
        .van-cell__value{
          flex: 1;
        }
        .van-search{
          padding: 0 10px 0 0;
          .van-search__content{
            padding-left: 8px;
          }
          .van-icon{
            color: #999;
          }
        }
      }
      .van-nav-bar__left,
      .van-nav-bar__right{
        position: static;
        width: 50px;
        height: 50px;
        line-height: 50px;
        text-align: center;
        padding: 0;
        .van-icon{
          margin: 0 auto;
        }
        .van-button{
          border-radius: 4px;
          font-size: 14px;
        }
        .van-button--danger{
          background-color: #E93B3D;
          border-color: #E93B3D;
        }
      }
      
    }
    .search_history{
      background-color: #fff;
      overflow: hidden;
      .van-cell{
        padding: 10px;
        .van-cell__title{
          flex: 1;
          text-align: left;
          .van-cell__label{
            margin-top: 10px;
            .van-tag{
              font-size: 12px;
              padding: 0 10px;
              margin: 0 10px 10px 0;
              height: 23px;
              line-height: 24px;
              background-color: #F0F2F5;
              color: #686868;
              overflow: hidden;
            }
          }
        }
        .van-icon{
          font-size: 22px;
          vertical-align: middle;
          line-height: 24px;
          position: absolute;
          right: 10px;
          top: 10px;
        }
      }
    }
  }
</style>
